@import "../../App/App.scss";

.mainboard {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.mainboard li {
	padding: 0;
	margin: 3rem 0;
}

.board-links {
	margin-bottom: 1.5rem;
}

.device-icon {
	width: 0.9rem;
	height: auto;
	margin: 0.25rem;
	color: $primary-color;
}

.no-device-in-room {
	width: 0.9rem;
	height: auto;
	color: lighten($color: $grey, $amount: 35);
	vertical-align: middle;
	margin: 0.25rem;
}

.board-badge {
	padding: 0.1rem 0.7rem 0.2rem 0.7rem;
	margin: 0.25rem;
	color: $white !important;
	border-radius: 8px;
	@include fluid-type(0.75rem, 0.85rem);
	font-family: $text-font;
	border: none;
	&.blue {
		background-color: $secondary-color;
	}
	&.red {
		background-color: $contrast-color;
	}
}

.board-badge:hover {
	color: $white;
	background-color: $contrast-color;
	text-decoration: none;
}

.board-badge:focus {
	outline: 0;
}

.board-supervisors {
	margin-top: 0.8rem;
}

.board-supervisor-badge {
	padding: 0.1rem 0.7rem 0.2rem 0.7rem;
	margin: 0.25rem;
	color: $primary-color;
	border-radius: 8px;
	@include fluid-type(0.75rem, 0.85rem);
	background-color: $white;
	font-family: $text-font;
	border: solid 1px lighten($color: $grey, $amount: 30);
	cursor: pointer;
	display: block;
}

.board-supervisor-badge:hover {
	color: $white;
	background-color: $secondary-color;
	text-decoration: none;
}

.board-supervisor-badge-selected {
	padding: 0.1rem 0.7rem 0.2rem 0.7rem;
	margin: 0.25rem;
	color: $secondary-color;
	border-radius: 8px;
	@include fluid-type(0.75rem, 0.85rem);
	background-color: $white;
	font-family: $text-font;
	border: solid 1px $secondary-color;
	cursor: pointer;
	display: block;
}

.board-supervisor-badge-selected:hover {
	padding: 0.1rem 0.7rem 0.2rem 0.7rem;
	margin: 0.25rem;
	color: $primary-color;
	border-radius: 8px;
	@include fluid-type(0.75rem, 0.85rem);
	background-color: $white;
	font-family: $text-font;
	border: solid 1px lighten($color: $grey, $amount: 30);
	cursor: pointer;
	display: block;
}

.board-card {
	background-color: $light-color;
	border-radius: 8px;
	padding: 0;
	margin: 0.25rem;
	width: 160px;
	height: 140px;
	position: relative;

	h3.room-name {
		@include fluid-type(0.8rem, 0.85rem);
		text-transform: uppercase;
		line-height: 1rem;
		letter-spacing: 0.2px;
		color: $primary-color;
		font-family: $title-font;
	}

	p {
		@include fluid-type(0.8rem, 0.85rem);
		padding: 0;
		margin: 0;
		color: $blue;
		letter-spacing: 0;
		line-height: 1rem;
	}
}

.board-card-body {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.board-card-footer {
	position: absolute;
	bottom: 0.7rem;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
}

.board-card-icon {
	width: auto;
	height: 1rem;
	margin: 0.25rem;
	color: $primary-color;
	cursor: pointer;
}

.board-card-icon:hover {
	color: $blue;
}

.board-table-icon {
	width: 1rem;
	height: auto;
	margin: 0 0.4rem;
	cursor: pointer;
	color: $primary-color;
}

.board-table-icon:hover {
	color: $blue;
}

.red-board-table-icon {
	width: 1rem;
	height: auto;
	margin: 0 0.4rem;
	cursor: pointer;
	color: $contrast-color;
}

.grey-board-table-icon {
	width: 1rem;
	height: auto;
	margin: 0 0.4rem;
	color: lighten($color: $grey, $amount: 35);
}

.board-table-icon-notification {
	background-color: $contrast-color;
	color: $white;
	font-size: 0.6rem;
	font-family: $title-font;
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 50%;
	position: absolute;
	top: 0.75rem;
	left: 0.8rem;
	text-align: center;
}

.board-alert {
	width: 100%;
	position: relative;
}

.board-card-icon-notification {
	background-color: $contrast-color;
	color: $white;
	font-size: 0.6rem;
	font-family: $title-font;
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 50%;
	position: absolute;
	top: 0.7rem;
	left: 0.7rem;
	text-align: center;
}

.board-card-empty {
	background-color: $light-contrast-color;
	border-radius: 7px;
	padding: 1rem;
	margin: 0.25rem;
	width: 160px;
	height: 140px;
	position: relative;

	h3.room-name {
		@include fluid-type(0.8rem, 0.85rem);
		text-transform: uppercase;
		line-height: 1rem;
		letter-spacing: 0.2px;
		color: $primary-color;
		font-family: $title-font;
	}
}

.add-senior-badge {
	padding: 0 0.75rem 0.25rem 0.75rem;
	margin: 0;
	color: $grey;
	border-radius: 10px;
	@include fluid-type(0.75rem, 0.8rem);
	background-color: $white;
	font-family: $text-font;
	border: solid 1px lighten($color: $grey, $amount: 30);
	cursor: pointer;
	display: inline-block;
}

.add-senior-badge:hover {
	color: $white;
	background-color: $grey;
	text-decoration: none;
}
